<template>
  <div class="ensemble-single" v-show="!isLoading">
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-2">{{ ensemble.name }}</h1>
          <h2 class="subtitle">{{ ensemble.city }}, {{ ensemble.state }}</h2>
        </div>
      </div>
    </section>
    
    <section class="ensemble-content">
      <div class="container">
        <div class="columns is-multiline is-variable is-8">
          <div class="column is-full-mobile is-half-tablet">
            <figure class="image">
              <img :src="ensemble.featuredImage" alt="Ensemble image" id="ensembleImage" />
            </figure>
          </div>
          <div class="column is-full-mobile is-half-tablet">
            <h2 class="subtitle is-3 biography-heading">Biography</h2> <!-- Fixed Heading -->
            <p class="is-size-6 description">{{ ensemble.description }}</p>
            <div class="groupSocialMedia">
              <a target="_blank" rel="noopener noreferrer" :href="ensemble.facebookLink" v-if="ensemble.facebookLink">
                <img src="../img/fb.png" alt="Facebook"/>
              </a>
              <a target="_blank" rel="noopener noreferrer" :href="ensemble.websiteLink" v-if="ensemble.websiteLink">
                <img src="../img/web.png" alt="Website" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import EnsembleService from "../services/ensembleService";

export default {
  name: "EnsembleSingle",
  data() {
    return {
      isLoading: false,
      ensemble: {},
    };
  },
  async mounted() {
    this.isLoading = true;
    this.ensemble = await EnsembleService.get(this.$route.params.id);
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
  margin-bottom: 40px;
  padding: 2rem;
}

.hero.is-primary {
  background-color: transparent;
}

h1.title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
}

h2.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  margin-top: 0.5rem;
}

.ensemble-content {
  padding: 2rem 1rem;

  /* Fix the color of the biography heading */
  .biography-heading {
    color: #2c3e50; /* Dark color for readability */
    font-family: 'Raleway';
  }

  .columns {
    align-items: flex-start;
  }

  .image {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  #ensembleImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    max-height: 450px;
    transition: transform 0.3s ease-in-out;
  }

  #ensembleImage:hover {
    transform: scale(1.05);
  }

  .description {
    margin-top: 1rem;
    line-height: 1.6;
    color: #333;
  }

  .groupSocialMedia {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;

    a {
      display: inline-block;
      margin-right: 10px;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-body {
    padding: 1rem;
  }

  h1.title {
    font-size: 2rem;
  }

  h2.subtitle {
    font-size: 1.25rem;
  }

  .ensemble-content {
    padding: 1.5rem 1rem;
  }

  #ensembleImage {
    max-height: 300px;
  }
}
</style>
